module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/cuizizine/cuizizine/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js","pages":"/home/runner/work/cuizizine/cuizizine/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/cuizizine/cuizizine"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"👨‍🍳 Cuizizine 👩‍🍳","short_name":"Cuizizine","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"/home/runner/work/cuizizine/cuizizine/static/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6e05f049a009b957153ec7dda9e464ae"},
    },{
      plugin: require('../node_modules/@marinda/gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[],"title":"👨‍🍳 Cuizizine 👩‍🍳","shortTitle":"Cuizizine","iconPath":"./static/images/favicon.png"},
    }]
