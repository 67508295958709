// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-marinda-gatsby-theme-recipes-src-pages-404-js": () => import("./../../../node_modules/@marinda/gatsby-theme-recipes/src/pages/404.js" /* webpackChunkName: "component---node-modules-marinda-gatsby-theme-recipes-src-pages-404-js" */),
  "component---node-modules-marinda-gatsby-theme-recipes-src-templates-recipe-js": () => import("./../../../node_modules/@marinda/gatsby-theme-recipes/src/templates/recipe.js" /* webpackChunkName: "component---node-modules-marinda-gatsby-theme-recipes-src-templates-recipe-js" */),
  "component---node-modules-marinda-gatsby-theme-recipes-src-templates-recipes-js": () => import("./../../../node_modules/@marinda/gatsby-theme-recipes/src/templates/recipes.js" /* webpackChunkName: "component---node-modules-marinda-gatsby-theme-recipes-src-templates-recipes-js" */),
  "component---node-modules-marinda-gatsby-theme-recipes-src-templates-tagged-recipes-js": () => import("./../../../node_modules/@marinda/gatsby-theme-recipes/src/templates/taggedRecipes.js" /* webpackChunkName: "component---node-modules-marinda-gatsby-theme-recipes-src-templates-tagged-recipes-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */)
}

